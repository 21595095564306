exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3ai4IjRFEqsTZ9NLjY8Xvh{width:100%}.XnQCfp949Xjp9kb8fs--k{color:#fff;display:flex;justify-content:flex-end;padding:.5em}._34h_71SzOwksOdMSTMl2Cp{width:100%;text-align:center}._3vJEI2NEf-uyGfN7h0WE8Z{display:flex;flex-direction:column;align-items:flex-end;justify-content:flex-end;font-size:.7em;color:#009a44}._3vJEI2NEf-uyGfN7h0WE8Z span{font-weight:bold}._3vJEI2NEf-uyGfN7h0WE8Z ._39b-VbgHUFtNX-mi7qU7Y1{flex:1}._3vJEI2NEf-uyGfN7h0WE8Z ._3CpcZynRnNtb2IYWJx70Ag{flex:1}", ""]);

// Exports
exports.locals = {
	"container": "_3ai4IjRFEqsTZ9NLjY8Xvh",
	"cashierInfo": "XnQCfp949Xjp9kb8fs--k",
	"spinnerContainer": "_34h_71SzOwksOdMSTMl2Cp",
	"cashierInfoContainer": "_3vJEI2NEf-uyGfN7h0WE8Z",
	"cashierName": "_39b-VbgHUFtNX-mi7qU7Y1",
	"shopName": "_3CpcZynRnNtb2IYWJx70Ag"
};