exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._21fxeT9n3bdej-qfVUVCUw ._3mzO_uSuhq88eoZ10Mdopo ._1hTGNiAiCR3XEzobN-z-ji{color:#8e2db0}._21fxeT9n3bdej-qfVUVCUw ._3N--h0mqr_spmXqICMEQNe{background-color:#8e2db0}._21fxeT9n3bdej-qfVUVCUw ._3N--h0mqr_spmXqICMEQNe ._19-b3pJh7Vp5SoALF_bxGj{background-color:#8e2db0}._21fxeT9n3bdej-qfVUVCUw ._2MEZr70hRIOL8gqJcVMnhm{color:#8e2db0}._21fxeT9n3bdej-qfVUVCUw ._2GqrHMh8Zo5-Df4JtnbhAl ._2MSADTzYRlUzb6k42rOFxl{color:#8e2db0}", ""]);

// Exports
exports.locals = {
	"ils-pick-3": "_21fxeT9n3bdej-qfVUVCUw",
	"instructions": "_3mzO_uSuhq88eoZ10Mdopo",
	"instructionsHeaderTitle": "_1hTGNiAiCR3XEzobN-z-ji",
	"results": "_3N--h0mqr_spmXqICMEQNe",
	"resultsHeader": "_19-b3pJh7Vp5SoALF_bxGj",
	"betNumber": "_2MEZr70hRIOL8gqJcVMnhm",
	"betsContainer": "_2GqrHMh8Zo5-Df4JtnbhAl",
	"headerOptionActive": "_2MSADTzYRlUzb6k42rOFxl"
};