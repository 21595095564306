exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1fI_TbKZk_fXF9bRa209aC{display:flex;flex-wrap:wrap;justify-content:center;border-radius:.5em !important;margin-bottom:4em}@media(min-width: 769px){._1fI_TbKZk_fXF9bRa209aC{margin-bottom:0;padding-top:2em}}._1fI_TbKZk_fXF9bRa209aC ._2vaA0bebP2tgnRvOhlHya0{display:flex;justify-content:flex-end;flex-wrap:wrap;width:100%}._1fI_TbKZk_fXF9bRa209aC ._2vaA0bebP2tgnRvOhlHya0 ._1A1MOtzWZ5-lSj6XE4xnzj{font-size:12px;margin:0 5px;font-weight:bold;cursor:pointer}._1fI_TbKZk_fXF9bRa209aC ._2vaA0bebP2tgnRvOhlHya0 .AAEwGoOrPoQVR56PduZ4g{color:#009a44}._1fI_TbKZk_fXF9bRa209aC ._2vaA0bebP2tgnRvOhlHya0 ._1rFfslXPWSmt4FhtDRCGdJ{color:#e61621}", ""]);

// Exports
exports.locals = {
	"gameContainer": "_1fI_TbKZk_fXF9bRa209aC",
	"btnLinesWrapper": "_2vaA0bebP2tgnRvOhlHya0",
	"btnLines": "_1A1MOtzWZ5-lSj6XE4xnzj",
	"btnAdd": "AAEwGoOrPoQVR56PduZ4g",
	"btnRemove": "_1rFfslXPWSmt4FhtDRCGdJ"
};