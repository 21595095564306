exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1jalxSTDOMsQLIXdrxeVye{color:#009a44;display:flex;width:100%;flex-direction:column;align-items:center;justify-content:center}._1R-6NEHh_Yicy15wEUi-dC{width:100%;background-color:#23323c}._3EEoNGix6ERParrKrYFWv2{width:100%;max-width:800px;flex:0 0 10%;display:flex;flex-direction:column;align-items:center;justify-content:center}._3EEoNGix6ERParrKrYFWv2 select{background-color:#e4e4e4;color:#484848;width:auto;font-weight:bold}._3EEoNGix6ERParrKrYFWv2 .UTSoAQEwgzeO_ILxinlq3{padding:0;font-size:13px;line-height:13px;height:min-content;min-height:35px;vertical-align:baseline;margin:0 .5em;width:auto;display:flex;align-items:center;justify-content:center}._3eSrOMizyBhM3NqYmrQWl1{width:100%}._3eSrOMizyBhM3NqYmrQWl1 ._2OZkHARmpovi61bqF74qkF ._1MQqFVachmnIo2vD7_WRDc{flex:1}._3eSrOMizyBhM3NqYmrQWl1 ._2e3eetqQ1NT02cWXY9-Ra1{padding:.5em .5em 0 .5em;font-size:13px}@media(min-width: 576px){._3eSrOMizyBhM3NqYmrQWl1 ._2e3eetqQ1NT02cWXY9-Ra1{font-size:15px}}._150g5gSVqTXaT4d4uwL1R{display:grid;grid-gap:.5em;grid-template-columns:repeat(auto-fit, minmax(150px, 1fr))}.jawLHrWE0TuSFZBUTM9Se{display:flex;justify-content:center;align-items:center}._3MJx70P-HbmCBQgTN0hewf{flex:1 1 80%;height:50vh;min-height:50vh;max-height:50vh;display:flex;align-items:center;justify-content:center}._3MJx70P-HbmCBQgTN0hewf ._2Y2jbnF4e3MwUdCgELkypz{display:flex;height:inherit;max-height:inherit;flex-direction:column;align-items:center;overflow-y:scroll}@media print{._3MJx70P-HbmCBQgTN0hewf ._2Y2jbnF4e3MwUdCgELkypz{width:83mm}}.M_906mk1g-CCe6cCB94pW{flex:0 0 10%;width:100%;display:flex;align-items:center;justify-content:center}.M_906mk1g-CCe6cCB94pW .lKMBmUjDmd4CRO02ftqoK{flex:1;max-width:200px;display:flex;justify-content:center}.bQq-9-A6mUDAfpxuyfFnz{padding:1em .5em;background-color:red;color:#fff;text-align:center}", ""]);

// Exports
exports.locals = {
	"container": "_1jalxSTDOMsQLIXdrxeVye",
	"filtersContainerBg": "_1R-6NEHh_Yicy15wEUi-dC",
	"filtersContainerWrapper": "_3EEoNGix6ERParrKrYFWv2",
	"filterButton": "UTSoAQEwgzeO_ILxinlq3",
	"filterContainer": "_3eSrOMizyBhM3NqYmrQWl1",
	"gamesFilterContainer": "_2OZkHARmpovi61bqF74qkF",
	"gameFilterSelector": "_1MQqFVachmnIo2vD7_WRDc",
	"filterHeader": "_2e3eetqQ1NT02cWXY9-Ra1",
	"filterOptionsContainer": "_150g5gSVqTXaT4d4uwL1R",
	"filterOptionsContainerLoader": "jawLHrWE0TuSFZBUTM9Se",
	"printoutContainer": "_3MJx70P-HbmCBQgTN0hewf",
	"printInContainer": "_2Y2jbnF4e3MwUdCgELkypz",
	"buttonsContainer": "M_906mk1g-CCe6cCB94pW",
	"singleButtonWrapper": "lKMBmUjDmd4CRO02ftqoK",
	"errorContainer": "bQq-9-A6mUDAfpxuyfFnz"
};