exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1cHCt_LAVLY70h0NMXuqaG{display:flex;flex-direction:column;justify-content:center;align-items:center;align-content:center;width:100%;height:100%}._1cHCt_LAVLY70h0NMXuqaG ._2IxiNdzy5Ik1kNjWHlrfMi{flex:1 1 80%;width:100%;padding:1em;color:#fff;border-top:1px solid #bebebe;border-bottom:1px solid #bebebe;height:fit-content}._1cHCt_LAVLY70h0NMXuqaG ._2IxiNdzy5Ik1kNjWHlrfMi ._23jleW3__5R3KirzdZWEwJ{max-width:300px;margin:auto;text-align:center}._1cHCt_LAVLY70h0NMXuqaG .aokwZ1rXv-7rwRxhlbvVu{color:red;text-align:center;display:block;margin:.5em 0}._1cHCt_LAVLY70h0NMXuqaG ._8-ffoCPuAigW65SWRtPhS{flex:0 1 20%}._1cHCt_LAVLY70h0NMXuqaG ._3HqBkEcy1ejaNVB1wavOrG{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;color:#009a44}._1cHCt_LAVLY70h0NMXuqaG ._3HqBkEcy1ejaNVB1wavOrG label{font-weight:normal}._1cHCt_LAVLY70h0NMXuqaG ._3HqBkEcy1ejaNVB1wavOrG .Eur8GXH-S1dktn36SWxuM{width:80%}._1cHCt_LAVLY70h0NMXuqaG ._3HqBkEcy1ejaNVB1wavOrG .fnFOG9NTbCOlZ7N_3PwFA{width:100%;color:#fff;font-size:1em;background-color:#239a48;padding:.5em .5em;margin:.5em 0;border-radius:5px}", ""]);

// Exports
exports.locals = {
	"container": "_1cHCt_LAVLY70h0NMXuqaG",
	"cameraContainer": "_2IxiNdzy5Ik1kNjWHlrfMi",
	"cameraView": "_23jleW3__5R3KirzdZWEwJ",
	"error": "aokwZ1rXv-7rwRxhlbvVu",
	"buttonsContainer": "_8-ffoCPuAigW65SWRtPhS",
	"manualInputContainer": "_3HqBkEcy1ejaNVB1wavOrG",
	"manualInputFormContainer": "Eur8GXH-S1dktn36SWxuM",
	"manualInput": "fnFOG9NTbCOlZ7N_3PwFA"
};